<template>
  <div>
    <GmapMap
      :center="center"
      :zoom="zoom"
      :options="{
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: false,
        disableDefaultUI: false,
      }"
      style="width: 100%; height: 100vh"
    >
    
      <gmap-info-window :options="infoOptions" :position="infoPosition" :opened="infoOpened" @closeclick="infoOpened = false">
        <div v-show="urlImage">
          <b-img-lazy style="width: 25vh" :src="urlImage" center fluid />
        </div>
        <p style="width: 25vh">{{ infoContent }}</p>
      </gmap-info-window>
      <GmapMarker :clickable="true" :key="index" v-for="(m, index) in markers" :icon="m.icon" :position="m.position" @click="toggleInfo(m, index)" />
    </GmapMap>
  </div>
</template>

<script>
  import axios from 'axios';
  import API from '@/connect/config';

  import { gmapApi } from 'vue2-google-maps';
  import { icon } from 'leaflet';

  export default {
    components: {
      BImgLazy,
    },
    data() {
      return {
        infoPosition: null,
        infoContent: null,
        infoOpened: false,
        infoOptions: {
          pixelOffset: {
            width: 1,
            height: -34,
          },
        },
        urlImage: '',
        // 13.66033, 100.4574967
        center: { lat: 13.66033, lng: 100.4574967 },
        zoom: 14,
        currentPlace: null,
        markers: [],
        label: {
          text: 'label',
          color: 'black',
          fontWeight: 'bold',
          fontSize: '12px',
        },
        blueIcon: {
          url: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
          scaledSize: { width: 25, height: 41 },
          labelOrigin: { x: 16, y: -10 },
        },
        redIcon: {
          url: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
          scaledSize: { width: 25, height: 41 },
          labelOrigin: { x: 16, y: -10 },
        },
        orangeIcon: {
          url: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
          scaledSize: { width: 25, height: 41 },
          labelOrigin: { x: 16, y: -10 },
        },
      };
    },
    computed: {
      google: gmapApi,
    },
    async mounted() {
      const urlParams_URL = new URLSearchParams(window.location.search);

      let refresh_token = urlParams_URL.get('refresh_token');
      let uId = urlParams_URL.get('uId');
      let userId = urlParams_URL.get('userId');

      localStorage.setItem('refresh_token', JSON.stringify(refresh_token));
      const jwt = await this.$store.dispatch('getJwtDecode', refresh_token);
      localStorage.setItem('profile_visitor', JSON.stringify({ userId: userId, ...jwt }));

      const url = `${API}ticket/${userId}?uId=${uId}&_page=1&_limit=100`;

      const headers = {
        Authorization: refresh_token,
      };
      const res = await axios.get(url, headers);
      console.log(res);
      let total = Math.ceil(Number(res.data.message.total) / 100);
      let res_data = [];
      for (let index1 = 1; index1 <= total; index1++) {
        const url_l = `${API}ticket/${userId}?uId=${uId}&_page=${total}&_limit=100`;
        const res_l = await axios.get(url_l, headers);
        res_data.push(...res_l.data.message.result);
      }

      this.markers = res_data.map((el, index) => ({
        ...el,
        num: index,
        position: {
          lat: Number(el.gps.latitude),
          lng: Number(el.gps.longitude),
        },
        draggable: false,
        visible: el.status === 'open' ? true : false,
        icon: el.type === 'inform' ? this.redIcon : el.type === 'maintenance' ? this.orangeIcon : this.blueIcon,
      }));
      console.log(this.markers);
      //   set auto location
      // this.geolocate();
    },
    methods: {
      toggleInfo: function (marker, key) {
        console.log(marker);
        this.center = marker.position;
        this.infoPosition = marker.position;
        this.infoContent = marker.title;
        this.urlImage = '';
        // http://46.137.251.86:4000/chat/638b1f0bf822880025e292c5?ticketId=65c6ea3bb54487002136944b&_page=1&_limit=100&_sort=1
        this.$store.dispatch('getData', `chat/${JSON.parse(localStorage.getItem('profile_visitor')).userId}?ticketId=${marker.ticketId}`).then(async (res) => {
          console.log(res.message.result);
          if (res.message.result[0]?.chatImage) {
            this.$store.dispatch('getImageURL', res.message.result[0].chatImage[0]).then(async (res) => {
              console.log(res);
              this.urlImage = res;
            });
          }
        });
        if (this.infoCurrentKey == key) {
          this.infoOpened = !this.infoOpened;
        } else {
          this.infoOpened = true;
          this.infoCurrentKey = key;
        }
      },
      setPlace(place) {
        this.currentPlace = place;
      },
      geolocate: function () {
        navigator.geolocation.getCurrentPosition((position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        });
      },
    },
  };
</script>

<style></style>
